
import { Options, Vue, Prop, Watch } from "vue-decorator";

@Options({})
export default class MultiSelect extends Vue {
    @Prop()
    public options: any[] = [];

    @Prop()
    public optionKey: string | null = null;

    @Prop()
    public optionDisplay: string | null = null;

    @Prop()
    public modelValue: any[] = [];

    public editing: boolean = false;

    public summary = "None Selected";

    public handleFocusOut(e: Event) {
        this.editing = false;
        this.$emit('update:modelValue', this.modelValue);
    }

    public edit(e: Event) {
        if(this.editing) {
            this.handleFocusOut(e);
        } else {
            this.editing = true;
        }
    }

    public get editContainerHeight() {
        // TODO? maybe find clipping parent and calc the max height we can be?
        return "400px";
    }

    @Watch("modelValue")
    onmodelValueChanged(val: any, oldVal: any) {
        let names: any[] = [];

        if(this.optionKey != null) {
            names = this.options.filter(o => val.indexOf(o[this.optionKey!]) >= 0);
        } else {
            names = this.options.filter(o => val.indexOf(o) >= 0);
        }

        if(this.optionDisplay != null)
        {
            names = names.map(o => o[this.optionDisplay!]);
        } else {
            names = names.map(o => o);
        }

        if(names.length === 0) {
            return "None Selected"
        } else if(names.length === this.options.length) {
            return `All ${this.options.length} Selected`
        } else {
            return names.join(", ");
        }
    }

    public selected(option: any) {
        if(this.optionKey != null) {
            return this.modelValue.indexOf(option[this.optionKey]) >= 0;
        } else {
            return this.modelValue.indexOf(option) >= 0;
        }
    }

    public toggle(option: any) {
        let index = -1;

        if(this.optionKey != null) {
            index = this.modelValue.indexOf(option[this.optionKey]);
        } else {
            index = this.modelValue.indexOf(option);
        }

        if(index >= 0) {
            this.modelValue.splice(index, 1);
        } else {
            this.modelValue.push(this.optionKey != null ? option[this.optionKey] : option);
        }

        this.modelValue.sort((a,b) => {
            if(this.optionKey != null){
                return this.options.findIndex(o => o[this.optionKey!] == a) - this.options.findIndex(o => o[this.optionKey!] == b);
            }

            return this.options.indexOf(a) - this.options.indexOf(b);
        });

        this.$emit("change");
    }
}

