import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b39df4b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-string" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "multi-select",
    onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFocusOut && _ctx.handleFocusOut(...args))),
    tabindex: "0"
  }, [
    _createElementVNode("div", {
      class: "input-container",
      role: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit())),
      ref: "field"
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.onmodelValueChanged(_ctx.modelValue)), 1)
    ], 512),
    (_ctx.editing)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expanded-container",
          style: _normalizeStyle({maxHeight: _ctx.editContainerHeight})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("p", {
              class: _normalizeClass(["item", { 'selected': _ctx.selected(option) }]),
              role: "button",
              key: _ctx.optionKey != null ? option[_ctx.optionKey] : option,
              onClick: ($event: any) => (_ctx.toggle(option))
            }, _toDisplayString(_ctx.optionDisplay != null ? option[_ctx.optionDisplay]: option), 11, _hoisted_2))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ], 32))
}