
import { Options, Vue } from "vue-class-component";
import StreamFeed from "@/components/Feeds/StreamFeed.vue";
import SmallCrudGrid from "@/components/Input/SmallCrudGrid.vue";
import EditableField from "@/components/Input/EditableField.vue";
import OnAsync from "@/directives/OnAsync";
import { MetadataService, StaticContentService, StreamListMetadata } from "@/api";

@Options({
    components: {
        StreamFeed,
        SmallCrudGrid,
        EditableField
    },
    directives: {
        OnAsync
    }
})
export default class StreamManagement extends Vue {
    streamListMetadata: StreamListMetadata | null = null;

    created() {
        this.load();
    }

    public async load() {
        try {
            this.streamListMetadata = await StaticContentService.streamListMetadata();
        } catch {
             this.streamListMetadata = { NonUserTwitchStreams: []};
        }
    }

    public async saveNonUserTwitchStreams() {
        await MetadataService.updateNonUserTwitchStreams(this.streamListMetadata?.NonUserTwitchStreams);
        await this.load();
    }

    public addUser() {
        if(this.streamListMetadata == null) return;

        this.streamListMetadata.NonUserTwitchStreams = this.streamListMetadata?.NonUserTwitchStreams ?? [];
        this.streamListMetadata?.NonUserTwitchStreams.push("New Twitch Username");
    }

    public updateUser(index: number, newVal: string) {
        if(this.streamListMetadata?.NonUserTwitchStreams == null) return;
        this.streamListMetadata.NonUserTwitchStreams[index] = newVal;
    }

    public deleteUser(user: string) {
        if(this.streamListMetadata?.NonUserTwitchStreams == null) return;

        this.streamListMetadata.NonUserTwitchStreams.splice(this.streamListMetadata.NonUserTwitchStreams.indexOf(user), 1);
    }
}
