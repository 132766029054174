
import { Game, AccountsService, AccountStatus, Claim, UserAccount, ProfilesService } from "@/api";
import { Options, Vue } from "vue-class-component";
import MultiSelect from "@/components/Input/MultiSelect.vue";
import { GameStore } from "@/store/games";
import OnAsync from "@/directives/OnAsync";

@Options({
    components: {
        MultiSelect
    },
    directives: {
        OnAsync
    }
})
export default class UserEdit extends Vue {
    user: UserAccount | null = null;

    accountStatuses: AccountStatus[] = [];
    statusSavable = false;
    statusSaving = false;
    statusSaved = true;

    allClaims: Claim[] = [];
    userClaims: any[] = [];
    permissionsSavable = false;
    permissionsSaving = false;
    permissionsSaved = true;

    games: Game[] = [];
    userModeratingGames: string[] = [];
    modGamesSavable = false;
    modGamesSaving = false;
    modGamesSaved = true;

    async created() {
        await this.loadUser();

        this.games = (await GameStore.get()).games;
        this.allClaims = Object.values(Claim).filter(c => c != "None");
        this.accountStatuses = Object.values(AccountStatus);
    }

    async loadUser() {
        const id = <string>this.$route.params["id"];

        if(id == null) return;

        const user = await AccountsService.getUserAccount(id);
        this.acceptUser(user);
    }

    acceptUser(user: UserAccount) {
        this.user = user;
        this.userClaims = this.user.UserClaims!.split(", ");
        this.userModeratingGames = this.user.ModeratingGames ?? [];

        this.$route.name = this.user!.Username;
    }

    async toggleStreamVisibility() {
        if(this.user?.UserId == null) return;

        await ProfilesService.toggleStreamVisibility(this.user.UserId);
        await this.loadUser();
    }

    
    async toggleAutoValidation() {
        if(this.user?.UserId == null) return;

        await AccountsService.toggleAutoValidation(this.user.UserId);
        await this.loadUser();
    }

    claimsChanged() {
        this.permissionsSavable = true;
        this.permissionsSaved = false;
        this.permissionsSaving = false;
    }

    async savePermissions() {
        if(this.permissionsSavable == false || this.user == null) return;

        this.permissionsSavable = false;
        this.permissionsSaved = false;
        this.permissionsSaving = true;

        this.user.UserClaims = this.userClaims.join(", ") as Claim;

        try
        {
            const updated = await AccountsService.updatePermissions(this.user.UserId!, this.user);
            this.acceptUser(updated);
            this.permissionsSaved = true;
            this.permissionsSavable = false;
        }  catch{
            this.permissionsSavable = true;
        }
        
        this.permissionsSaving = false;
    }

    modGamesChanged() {
        this.modGamesSavable = true;
        this.modGamesSaved = false;
        this.modGamesSaving = false;
    }

    async saveModerating() {
        if(this.modGamesSavable == false || this.user == null) return;

        this.modGamesSavable = false;
        this.modGamesSaved = false;
        this.modGamesSaving = true;

        this.user.ModeratingGames = this.userModeratingGames;

        try
        {
            const updated = await AccountsService.updateModeratingGames(this.user.UserId!, this.user);
            this.acceptUser(updated);
            this.modGamesSaved = true;
            this.modGamesSavable = false;
        }  catch{
            this.modGamesSavable = true;
        }
        
        this.modGamesSaving = false;
    }

    statusChanged() {
        this.statusSavable = true;
        this.statusSaved = false;
        this.statusSaving = false;
    }

    async saveStatus() {
        if(this.statusSavable == false || this.user == null) return;

        this.statusSavable = false;
        this.statusSaved = false;
        this.statusSaving = true;

        try
        {
            const updated = await AccountsService.updateStatus(this.user.UserId!, this.user);
            this.acceptUser(updated);
            this.statusSaved = true;
            this.statusSavable = false;
        }  catch{
            this.statusSavable = true;
        }
        
        this.statusSaving = false;
    }
}
