
import { Options, Vue } from "vue-class-component";
import { Guid } from 'js-guid';
import EditableField from "@/components/Input/EditableField.vue";
import MultiSelect from "@/components/Input/MultiSelect.vue";
import DragReorder from "@/directives/DragReorder";
import OnAsync from "@/directives/OnAsync";
import SmallCrudGrid from "@/components/Input/SmallCrudGrid.vue";
import { Category, Game, GamesService, RunnableSegment } from "@/api";
import { useToast } from "vue-toastification";
import { GameStore } from "@/store/games";

@Options({
    props: {},
    components: {
        EditableField,
        MultiSelect,
        SmallCrudGrid
    },
    directives: {
        DragReorder,
        OnAsync
    }
})
export default class Games extends Vue {
    game: Game | null = null;
    category: Category | null = null;
    filter: any | null = null;
    editingCategory = false;
    editingSegment = false;
    toast = useToast();
    segment: RunnableSegment | null = null;

    async created() {
        await this.refresh();
    }

    async refresh() {
        const id = <string>this.$route.params["id"];

        if(id == null) return;

        if(id == "create") {
            this.game = {
                Name: "New Game",
                SortOrder: 99,
                UrlStub: "newgame",
                Difficulties: ["Easy", "Legendary"],
                Categories: [],
                RunnableSegments: [],
                Rules: "Play the game",
                Featured: false
            };

            return;
        }

        this.game = await GamesService.getGame(id);

        this.$route.name = this.game!.Name;
    }

    async save() {
        if(this.game == null) return;

        try {
            this.game = await GamesService.upsertGame1(this.game);

            await GameStore.refresh();

            this.toast.success("Saved");
        } catch (e : any) {
            let message = e.toString();

            try {
                var errResponse = JSON.parse(e.body);
                message = Object.keys(errResponse.errors).map(k => k + ": " + errResponse.errors[k].join(", ")).join("\r\n");
            } catch {}

            this.toast.error("Save failed: " + message);
        }
    }

    addCategory() {
        if(this.game == null) return;
        if(this.game.Categories == null) this.game.Categories = [];

        let newCat = <Category>
        {
            Id: Guid.EMPTY,
            Name: "New Category",
            UrlStub: "newcategory",
            Difficulties: <string[]>[],
            AllowedSegments: <any[]>[],
            Featured: false,
            Rules: "",
            RequiredPartners: 0,
            AllowedPartners: 0,
            PointsMultiplier: 1,
            Filters: [],
            SortOrder: this.game.Categories.length
        };

        this.game?.Categories.push(newCat);
        this.editCategory(newCat);
    }

    async deleteCategory(cat: Category) {
        if(this.game == null) return;
        if(this.game.Categories == null) this.game.Categories = [];

        if(await this.$confirm()) {
            this.game?.Categories.splice(this.game?.Categories.indexOf(cat), 1);
        }
    }

    editCategory(cat: any) {
        this.editingCategory = true;
        this.category = cat;
    }

    saveCategory() {
        this.editingCategory = false;
        // Set category to null after css transition is done
        setTimeout(() => { 
            this.category = null 
            this.filter = null;
        }, 250);
    }

    reorderCategories() {
        if(this.game == null) return;
        if(this.game.Categories == null) this.game.Categories = [];

        let i = 0;
        for(let cat of this.game.Categories)
        {
            cat.SortOrder = i++;
        }
    }

    editSegment(segment: RunnableSegment) {
        this.editingSegment = true;
        this.segment = segment;
    }

    saveSegment() {
        this.editingSegment = false;
        // Set category to null after css transition is done
        setTimeout(() => this.segment = null, 250);
    }

    addFilter() {
        if(this.category == null) return;
        if(this.category.Filters == null) this.category.Filters = [];

        let newFilter =
        {
            Id: Guid.newGuid().toString(),
            Name: "New Filter",
            Values: [],
            AppliesToIndividualLevels: false,
            DefaultValue: undefined,
            Required: false,
        };

        this.category?.Filters.push(newFilter);
        this.editFilter(newFilter);
    }

    async deleteFilter(filter: any) {
        if(this.category == null) return;
        if(this.category.Filters == null) this.category.Filters = [];

        if(await this.$confirm({message: "Are you sure? Deleting a filter will not change any existing runs."})) {
            this.category?.Filters.splice(this.category?.Filters.indexOf(filter), 1);
            this.filter = null;
        }
    }

    editFilter(filter: any) {
        this.filter = filter;
    }

    addFilterValue() {
        let newVal =
        {
            Id: Guid.newGuid().toString(),
            Name: "New Filter",
            Description: "Filter value description"
        };

        this.filter.Values.push(newVal);
    }

    async deleteFilterValue(filter: any) {
        if(await this.$confirm({message: "Are you sure? Deleting a filter value will not change any existing runs."})) {
            this.filter.Values.splice(this.filter.Values.indexOf(filter), 1);
        }
    }

    addIL() {
        if(this.game == null) return;
        if(this.game.RunnableSegments == null) this.game.RunnableSegments = [];

        let newIL =
        {
            Id:  Guid.EMPTY,
            Name: "New Level",
            UrlStub: "newlevel",
            Points: 100,
            SortOrder: this.game.RunnableSegments.length
        };

        this.game?.RunnableSegments.push(newIL);
    }

    reorderILs() {
        if(this.game == null) return;
        if(this.game.RunnableSegments == null) this.game.RunnableSegments = [];

        let i = 0;
        for(let seg of this.game.RunnableSegments)
        {
            seg.SortOrder = i++;
        }

        let sortingArr = this.game.RunnableSegments.map(l => l.Id);

        for(let cat of this.game.Categories ?? [])
        {
            if(cat.AllowedSegments == null) continue;

            cat.AllowedSegments.sort((a, b) => {  
                return sortingArr.indexOf(a) - sortingArr.indexOf(b);
            });
        }
    }

    async deleteIL(il: RunnableSegment) {
        if(this.game == null) return;
        if(this.game.RunnableSegments == null) this.game.RunnableSegments = [];
        if(il.Id == null) return;

        var result = await this.$confirm({
            message: "Deleting a level will remove it from all categories as well"
        });

        if(result) {
            this.game.RunnableSegments.splice(this.game?.RunnableSegments.indexOf(il), 1);

            for(let cat of this.game.Categories ?? []) {
                if(cat.AllowedSegments == null) continue;

                let index = cat.AllowedSegments.indexOf(il.Id);
                if(index >= 0) {
                    cat.AllowedSegments.splice(index, 1);
                }
            }
        }
    }

    addDifficulty() {
        if(this.game == null) return;
        if(this.game.Difficulties == null) this.game.Difficulties = [];
        this.game.Difficulties.push("New Difficulty");
    }

    reorderDifficulties() {
        if(this.game == null) return;
        if(this.game.Difficulties == null) this.game.Difficulties = [];

        for(let cat of this.game.Categories ?? [])
        {
            if(cat.Difficulties == null) continue;

            cat.Difficulties.sort((a, b) => {  
                return this.game!.Difficulties!.indexOf(a) - this.game!.Difficulties!.indexOf(b);
            });
        }
    }

    updateDifficulty(index: number, newVal: string) {
        if(this.game == null) return;
        if(this.game.Difficulties == null) this.game.Difficulties = [];

        let oldVal = this.game.Difficulties[index];

        this.game.Difficulties[index] = newVal;

        for(let cat of this.game?.Categories ?? []) {
            if(cat.Difficulties == null) continue;

            let oldIndex = cat.Difficulties.indexOf(oldVal);
            if(oldIndex >= 0) {
                cat.Difficulties[oldIndex] = newVal;
            }
        }
    }

    async deleteDifficulty(diff: string) {
        if(this.game == null) return;
        if(this.game.Difficulties == null) this.game.Difficulties = [];

        var result = await this.$confirm({
            message: "Deleting a difficulty will remove it from all categories as well"
        });

        if(result) {
            this.game?.Difficulties.splice(this.game?.Difficulties.indexOf(diff), 1);
            
            for(let cat of this.game?.Categories || []) {
                if(cat.Difficulties == null) continue;

                let index = cat.Difficulties.indexOf(diff);
                if(index >= 0) {
                    cat.Difficulties.splice(index, 1);
                }
            }
        }
    }
}
