import { Directive, VNode } from "vue";

export interface DragConfig {
    type: string;
    item?: any;
    destination?: any[]
}


class DragReorderWorkingSet {
    static Values: {[key: string]: any} = {};
}

const DragReorder: Directive<HTMLElement & VNode, DragConfig> = {
    mounted(el, binding) {
        if(binding.value == null || binding.value.type == null) {
            throw "No value provided to DragReorder directive";
        }

        if(binding.arg == "target") {
            if(binding.value == null || binding.value.destination == null) {
                throw "No destination provided to DragReorder target directive";
            }

            el.addEventListener("dragover", (ev: DragEventInit & Event) => {
                ev.preventDefault();
                ev.cancelBubble = true;
                if(ev.dataTransfer == null) return;

                ev.dataTransfer.dropEffect = "move";
                el.classList.add("dragging-over");
            });

            el.addEventListener("dragleave", (ev: DragEventInit & Event) => {
                ev.preventDefault();
                ev.cancelBubble = true;
                if(ev.dataTransfer == null) return;

                el.classList.remove("dragging-over");
            });

            el.addEventListener("drop", (ev: DragEventInit & Event) => {
                ev.preventDefault();
                ev.cancelBubble = true;
                const dest = binding.value.destination;
                if(ev.dataTransfer == null || dest == null) return;

                var objKey = ev.dataTransfer.getData("text/plain");
                var obj = DragReorderWorkingSet.Values[objKey];

                const existingIndex = dest.indexOf(obj);
                const neighborIndex = dest.indexOf(binding.value.item);

                el.classList.remove("dragging-over");

                if(existingIndex == neighborIndex) return;

                // Remove existing
                dest.splice(existingIndex, 1);

                // Insert into correct position
                dest.splice(neighborIndex, 0, obj);

                el.dispatchEvent(new Event("reorder"));
            });
        } else {
            el.setAttribute("draggable", "true");
            el.addEventListener("dragstart", (ev: DragEventInit) => { 
                if(ev.dataTransfer == null) return;

                const type = "haloruns/" + binding.value.type;
                DragReorderWorkingSet.Values[type] = binding.value.item;
                ev.dataTransfer.setData("text/plain", type);
                ev.dataTransfer.effectAllowed = "move";
                ev.dataTransfer.setDragImage(el.parentElement!, 0, 0);
            });
        }
    }
};

export default DragReorder;