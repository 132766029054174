
import { Options, Vue, Prop, Watch } from "vue-decorator";

@Options({})
export default class EditableField extends Vue {
    @Prop()
    public value: any;

    public temp: any = null;
    public editing: boolean = false;

    public edit() {
        this.editing = true;
        this.temp = this.value;
    }

    public save() {
        this.editing = false;
        this.$emit("value", this.temp);
        this.temp = undefined;
    }

    public reset() {
        this.editing = false;
        this.$emit("value", this.value);
        this.temp = undefined;
    }
}
