
import { Options, Vue, Prop, Watch } from "vue-decorator";
import DragReorder from "@/directives/DragReorder";

@Options({
    directives: {
        DragReorder
    }
})
export default class SmallCrudGrid extends Vue {
    @Prop()
    public rows: any[] = [];

    @Prop()
    public rowKey: string | null = null;

    @Prop()
    public dragReorder: boolean = false;

    get hasEdit() {
        return this.$attrs.onEdit != null;
    }

    handleAdd() {
        this.$emit("add");
    }

    handleEdit(row: any) {
        this.$emit("edit", row);
    }

    handleDelete(row: any) {
        this.$emit("delete", row);
    }

    handleReorder() {
        this.$emit("reorder");
    }
}
