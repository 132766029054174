
import { Options, Vue, Prop, Watch } from "vue-decorator";

export interface PagedData 
{
    Items?: Array<any>;
    Page?: number;
    Size?: number;
    TotalItems?: number | null;
    Search?: string | null;
}

@Options({})
export default class PagedTable extends Vue {
    @Prop()
    public headers: string[] = [];

    @Prop()
    public page: PagedData | null = null;

    @Prop()
    public rowKey: string | null = null;

    get usingSlotHeaders() {
        return typeof(this.headers) === "number";
    }

    get totalColumns() {
        return (typeof(this.headers) === "number" ? this.headers : this.headers.length) + ((this.hasEdit || this.hasAdd) ? 1 : 0);
    }

    get hasAdd() {
        return this.$attrs.onAdd != null;
    }

    get hasEdit() {
        return this.$attrs.onEdit != null;
    }

    get hasDelete() {
        return this.$attrs.onDelete != null;
    }

    get visibleIndices() {
        const currentPage = this.page?.Page ?? 0,
            totalPages = Math.ceil(this.page!.TotalItems! / this.page!.Size!),
            delta = 2,
            range = [],
            rangeWithDots = [];
        let l;

        range.push(1);  

        if (totalPages <= 1){
            return range;
        }

        for (let i = currentPage - delta + 1; i <= currentPage + delta + 1; i++) {
            if (i < totalPages && i > 1) {
                range.push(i);
            }
        }  
        range.push(totalPages);

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push({val: l + 1, navigable: true});
                } else if (i - l !== 1) {
                    rangeWithDots.push({val: '...', navigable: false});
                }
            }
            rangeWithDots.push({val: i, navigable: true});
            l = i;
        }

        return rangeWithDots;
    }



    handleAdd() {
        this.$emit("add");
    }

    handleEdit(row: any) {
        this.$emit("edit", row);
    }

    handleDelete(row: any) {
        this.$emit("delete", row);
    }

    handlePage(pageIndex: number) {
        if(this.page?.Page == pageIndex) return;

        this.$emit("page", pageIndex);
    }
}
