
import { Options, Vue } from 'vue-class-component'
import { GameStore } from '@/store/games'
import PointsService from '@/services/PointsService';
import TimeService from '@/services/TimeService';
import { RunSubmissionEntry, RunSubmissionsService, RecordReference, Game, Category, RunnableSegment, RunSubmission, RunParticipantInfo } from '@/api';
import { ContentLoader } from 'vue-content-loader';

interface SubmissionEntry {
  CurrentRun: any;
  Submission: RunSubmission;
}

interface RecordInfo {
  CategoryId: string;
  Difficulty: string;
  Duration: string;
  DurationSeconds: number;
  GameId: string;
  Id: string;
  RunnableSegmentId: string | null;
  OccurredAt: string;
  Participants: RunParticipantInfo[]
  PartitionKey: string;
  RunId: string;
  SubmittedAt: string;
  Tie: boolean;
  Timestamp: string;
}

interface InflatedSubmission {
    Id?: string;
    UserId?: string;
    GameId: string;
    CategoryId: string;
    GameName: string;
    CategoryName: string;
    Duration: string;
    Difficulty: string;
    RunnableSegmentId: string;
    RunnableSegmentName: string | null;
    Participants: RunParticipantInfo[];
    RecordDuration: string;
    RecordUsernames: string[];
    CurrentRunDuration: string | number | null;
    CurrentPoints: number | null;
    NewPoints: number;
    Filters: {[name: string]: string};
}

interface RecordReferenceExtra
{
    DurationSeconds: number;
}

@Options({
  components: {
      ContentLoader
  }
})
export default class RunSubmissionQueue extends Vue {
    pendingSubmissions: RunSubmissionEntry[] | null = null;
    records: (RecordReference & RecordReferenceExtra)[] = [];
    games: Game[] = [];

    public gameLookup: any;
    public categoryLookup: any;
    public levelLookup: any;
    public recordLookup: {[key: string]: RecordInfo} = {};

    async created() {
        await this.init();
    }

    async init() {
        let gamestore = await GameStore.get();
        this.games = gamestore.games;

        this.gameLookup = this.games.reduce((o :any ,g) => { 
            let catLookup = g.Categories?.reduce((oc: any, c) => { oc[c.Id!] = c; return oc; }, {});
            let levelLookup = g.RunnableSegments?.reduce((oc: any, c) => { oc[c.Id!] = c.Name; return oc; }, {});

            o[g.Id!] = { 
                Name: g.Name,
                Categories: catLookup,
                Levels: levelLookup,
                RunnableSegments: g.RunnableSegments
            };

            return o;
        }, {});
        
        await this.refresh();
    }

    async refresh() {
        this.pendingSubmissions = null;
        let submissionResponse = await RunSubmissionsService.getSubmissions();

        if((submissionResponse.Submissions?.length ?? 0) > 0) {
            this.$store.commit("attentionRequired", "/admin/queue");
        } else {
            this.$store.commit("attentionRequired", null);
        }

        if(submissionResponse.Submissions == null
            || submissionResponse.RecordInfo == null) return;

        this.pendingSubmissions = submissionResponse.Submissions;
        this.records = <RecordReference & RecordReferenceExtra[]>submissionResponse.RecordInfo;

        this.recordLookup = this.records.reduce((o,r) => {
            r.DurationSeconds = TimeService.stringToSeconds(r.Duration ?? "0");
            o[`${r.GameId}-${r.CategoryId}-${r.RunnableSegmentId}-${r.Difficulty}`] = r;
            return o;
        }, {} as any)
    }

    async approve(entry: InflatedSubmission) {
        if(entry?.Id == null) return;

        if(await this.$confirm({ message: "Are you sure you want to approve this submission?", confirmAlias: "Approve" })) {
            this.$loading(true);
            try {
                await RunSubmissionsService.verifySubmission(entry.Id);
            } catch(ex: any) {
                let start = ex.body.indexOf(":")+1;
                this.$toast.error("Unable to approve submission: " + ex.body.substr(start, ex.body.indexOf("\n") - start).toString());
            }
            await this.refresh();
            this.$loading(false);
        }
    }

    async reject(entry: InflatedSubmission) {
        if (entry?.Id == null) return;

        let [reject, reason] = await this.$prompt({ message: "Reason for rejection", confirmAlias: "Reject" });

        if(reject) {
            this.$loading(true);
            try {
                await RunSubmissionsService.rejectSubmission(entry.Id, reason ?? "Unspecified rejection reason");
            } catch(ex: any) {
                let start = ex.body.indexOf(":")+1;
                this.$toast.error("Unable to reject submission: " + ex.body.substr(start, ex.body.indexOf("\n") - start).toString());
            }
            await this.refresh();
            this.$loading(false);
        }
    }

    get inflatedSubmissions() {
        let submissions : InflatedSubmission[] = [];

        if(!this.pendingSubmissions) {
            return submissions;
        }

        for(let entry of this.pendingSubmissions) {
            if(entry.Submission == null) continue;

            let inflated = Object.assign({} as InflatedSubmission, entry.Submission);
            let game = this.gameLookup[inflated.GameId];
            let category = game.Categories[inflated.CategoryId];
            let segment = game.RunnableSegments.find((s: any) => s.Id == entry.Submission!.RunnableSegmentId);
            inflated.GameName = game.Name;
            inflated.CategoryName = category.Name;
            inflated.RunnableSegmentName = segment.Name;

            let record = this.recordLookup[`${entry.Submission.GameId}-${entry.Submission.CategoryId}-${entry.Submission.RunnableSegmentId}-${entry.Submission.Difficulty}`];
            
            inflated.RecordDuration = record?.Duration;
            inflated.RecordUsernames = record?.Participants.map(p => p.Username!);

            let maxPoints = segment.Points;

            if(category?.PointsMultiplier !== undefined && category?.PointsMultiplier !== null)
            {
                maxPoints *= category.PointsMultiplier;
            }

            if(entry.CurrentRun != null ) {
                let currentDurationSeconds = TimeService.stringToSeconds(entry.CurrentRun.Duration!);
                inflated.CurrentRunDuration = entry.CurrentRun.Duration!;

                inflated.CurrentPoints = PointsService.calculatePoints(record?.DurationSeconds ?? Number.MAX_SAFE_INTEGER, currentDurationSeconds, maxPoints);
            }

            inflated.NewPoints = PointsService.calculatePoints(record?.DurationSeconds ?? Number.MAX_SAFE_INTEGER, TimeService.stringToSeconds(inflated.Duration), maxPoints);

            inflated.Filters = {};

            for(let filterId in entry.Submission.FilterValues) {
                let filterValueId = entry.Submission.FilterValues[filterId];

                for(let filterDef of category.Filters) {
                    if(filterDef.Id == filterId) {
                        for(let filterVal of filterDef.Values) {
                            if(filterVal.Id == filterValueId) {
                                inflated.Filters[filterDef.Name] = filterVal.Name;
                            }
                        }
                    }
                }
            }

            submissions.push(inflated);
        }

        return submissions;
    }
}
