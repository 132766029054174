
import { Options, Vue } from "vue-class-component";
import LeaderboardKeySelector from "@/components/Boards/LeaderboardKeySelector.vue";
import { GameStore } from "@/store/games";
import { EventingService, LeaderboardKey } from "@/api";
import OnAsync from "@/directives/OnAsync";

export interface EventItem {
    Event: string;
    Payload: any;
}

export interface LeaderboardUpdateEvent {
    Key: LeaderboardKey;
    RunId: string;
    RunTimestamp: string;
}

@Options({
    props: {},
    components: {
        LeaderboardKeySelector,
    },
    directives: {
        OnAsync
    }
})
export default class Commands extends Vue {
    updateEvent: EventItem = {
        Event: "LeaderboardUpdateEvent",
        Payload: <LeaderboardUpdateEvent>{
            Key: {},
            RunId: "00000000-0000-0000-0000-000000000000",
            RunTimestamp: new Date().toISOString(),
        },
    };

    async rebuildLeaderboard() {
        this.updateEvent.Payload.RunTimestamp = new Date().toISOString();
        await EventingService.dispatchEvent(JSON.stringify(this.updateEvent));
    }

    async rebuildRecordboard() {
        this.updateEvent.Payload.RunTimestamp = new Date().toISOString();

        var leaderboardEvent = JSON.stringify(this.updateEvent);
        var recordboardEvent = JSON.parse(leaderboardEvent);
        recordboardEvent.Event = "RecordBoardUpdateEvent";

        await EventingService.dispatchEvent(JSON.stringify(recordboardEvent));
    }

    async rebuildFeatureboard() {
        const gs = await GameStore.get();
        const catName = gs.games.filter(g => g.Id == this.updateEvent.Payload.Key.GameId)
            .flatMap(g => g.Categories)
            .filter(c => c?.Id == this.updateEvent.Payload.Key.CategoryId)
            .map(c => c!.Name)[0];

        let recordboardEvent = {
            Event: "FeaturedRecordsUpdateEvent",
            Payload: {
                Key: {
                    Category: catName,
                },
            },
        };

        await EventingService.dispatchEvent(JSON.stringify(recordboardEvent));
    }

    async rebuildExtensionRecordboard() {
        let recordboardEvent = {
            Event: "ExtensionRecordsUpdateEvent",
            Payload: {
                Key: {
                    GameId: this.updateEvent.Payload.Key.GameId
                }
            }
        };

        await EventingService.dispatchEvent(JSON.stringify(recordboardEvent));
    }

    async rebuildAllGameBoards() {
        let recordboardEvent = {
            Event: "AllGameBoardsUpdateEvent",
            Payload: {
                GameId: this.updateEvent.Payload.Key.GameId,
            },
        };

        await EventingService.dispatchEvent(JSON.stringify(recordboardEvent));
    }

    async updateRecordFeed() {
        let recordFeedEvent = {
            Event: "RecordFeedUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(recordFeedEvent));
    }

    async updateRecentRecordBoard() {
        let recordFeedEvent = {
            Event: "RecentRecordsUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(recordFeedEvent));
    }

    async updateRunFeed() {
        let recordFeedEvent = {
            Event: "RunFeedUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(recordFeedEvent));
    }

    async updateOldestRecords() {
        let recordFeedEvent = {
            Event: "OldestRecordsUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(recordFeedEvent));
    }

    async rebuildAllUserProfiles() {
        let result = await this.$confirm({ message: "Are you sure? This takes a while..."});

        if(!result) return;

        let updateEvent = {
            Event: "AllUserProfilesUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(updateEvent));
    }

    async rebuildAllUserCareers() {
        let result = await this.$confirm({ message: "Are you sure? This takes a while..."});

        if(!result) return;

        let updateEvent = {
            Event: "AllUserCareersUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(updateEvent));
    }

    async rebuildAllUserHistories() {
        let result = await this.$confirm({ message: "Are you sure? This takes a while..."});

        if(!result) return;

        let updateEvent = {
            Event: "AllUserRunHistoryRollupUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(updateEvent));
    }

    async rebuildStreamCandidates() {
        let updateEvent = {
            Event: "StreamCandidateListUpdateEvent",
            Payload: {},
        };

        await EventingService.dispatchEvent(JSON.stringify(updateEvent));
    }
}
