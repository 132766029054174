
import { Options, Vue } from "vue-class-component";
import { Game, GamesService } from "@/api";


@Options({
    props: {},
})
export default class GameList extends Vue {
    games: Game[] | null = null;

    async created() {
        this.games = await GamesService.getAllGames();
    }

    gameSubtitle(game: Game) {
        return `${game.Categories!.length} categories and ${game.RunnableSegments!.length} runnable segments`
    }

    gameIconName(game: Game) {
        // TODO: hacky bs that needs replaced asap
        return game.Name!.replaceAll(":", "");
    }
}

