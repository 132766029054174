<template>
    <div class="admin hr-tile">
        <ul class="breadcrumbs">
            <li class="breadcrumb-item">
                <router-link :to="'/'">Home</router-link>
            </li>
            
            <li v-for="(match, i) in $route.matched" :key="match.path" class="breadcrumb-item">
                <router-link :to="match.path" v-if="i < $route.matched.length - 1">{{match.name}}</router-link>
                <span v-if="i == $route.matched.length - 1">{{match.name}}</span>
            </li>
        </ul>
    </div>
    <div class="admin hr-tile" v-if="$route.path == '/admin'">
        <div class="nav-grid">
            <router-link class="grid-item" to="/admin/queue">
                <i class="fas fa-clipboard-list fa-stroked has-text-primary item-icon"></i>
                <h3 class="item-title">Submission Queue</h3>
                <p class="item-subtitle">View and approve or reject pending run submissions</p>
            </router-link>
            <router-link class="grid-item" to="/admin/games">
                <i class="fas fa-cogs fa-stroked has-text-primary item-icon"></i>
                <h3 class="item-title">Game Management</h3>
                <p class="item-subtitle">Edit game configuration for categories, levels, and more</p>
            </router-link>
            <router-link class="grid-item" to="/admin/streams">
                <i class="fas fa-broadcast-tower fa-stroked has-text-primary item-icon"></i>
                <h3 class="item-title">Stream Management</h3>
                <p class="item-subtitle">Hide/show users in stream list, manage manual streams</p>
            </router-link>
            <router-link class="grid-item" to="/admin/users">
                <i class="fas fa-users-cog fa-stroked has-text-primary item-icon"></i>
                <h3 class="item-title">User Management</h3>
                <p class="item-subtitle">Reset passwords, delete, ban, freeze user accounts</p>
            </router-link>
            <router-link class="grid-item" to="/admin/commands">
                <i class="fas fa-database fa-stroked has-text-primary item-icon"></i>
                <h3 class="item-title">Admin Commands</h3>
                <p class="item-subtitle">Invoke actions to rebuild data, cleanup entries, etc</p>
            </router-link>
        </div>
        
    </div>
    <div v-if="$route.path != '/admin'">
        <router-view></router-view>
    </div>
</template>