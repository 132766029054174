
import { Options, Vue } from "vue-class-component";
import { AccountsService, UserProfile, AccountSummaryPagedResult, AccountStatus, AccountSummary } from "@/api";
import PagedTable from "@/components/Input/PagedTable.vue";


@Options({
    props: {},
    components: { PagedTable }
})
export default class UserList extends Vue {
    page: AccountSummaryPagedResult | null= null;
    userSearch: string | undefined = null!;

    accountStatusFilter: {name: string, value: string}[] = [];
    accountStatus: string | null = null;

    private timeout: number | undefined;

    async created() {
        for (const k in AccountStatus) {
            var kv = {name: k, value: AccountStatus[k as keyof typeof AccountStatus]};
            this.accountStatusFilter.push(kv);
        }

        await this.navigateToPage(0);
    }

    async navigateToPage(index: number) {
        this.page = await AccountsService.pageAccounts(index, this.userSearch, AccountStatus[this.accountStatus as keyof typeof AccountStatus]);
    }

    async filterChange() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.navigateToPage(this.page?.Page ?? 0);
        }, 250);
    }

    async edit(user: AccountSummary) {
        this.$router.push(`/admin/users/${user.UserId}`);
    }
}

